<template>
  <div>
  	<b-card
      no-body
      class="border p-2"
    >
	    <!-- Alert: No item found -->
	    <b-alert
	      variant="danger"
	      :show="roleData === undefined"
	    >
	      <h4 class="alert-heading">
	        Error fetching role data
	      </h4>
	      <div class="alert-body">
	        No Role found with this id. Check
	        <b-link
	          class="alert-link"
	          :to="{ name: 'role-list'}"
	        >
	          Role List
	        </b-link>
	        for other roles.
	      </div>
	    </b-alert>

	    <template v-if="roleData">

	      <b-row>
	        <b-col
	          cols="12"
	        >
	          <b-card
			    title="Role Details"
			  >
			    <b-card-body>
			      <h4><strong>Role: </strong>{{ roleData.name }}</h4>
			      <h4><strong>Status: </strong>
			      	<span v-if="roleData.is_active">Active</span>
			      	<span v-else class="text-danger">Archived</span>
			      </h4>
			      <h4><strong>Permissions:</strong></h4>
			    </b-card-body>
			    <b-table
			      striped
			      responsive
            sticky-header
			      :items="permissionsData"
			      class="mb-0"
			    >

			      <template #cell(subject)="data">
			        {{ data.value }}
			      </template>
			      <template #cell()="data">
			        <b-form-checkbox
			          disabled
			          :checked="data.value"
			        />
			      </template>

			    </b-table>
			  </b-card>
	        </b-col>
	      </b-row>

	    </template>
	</b-card>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox
} from 'bootstrap-vue'
import roleStoreModule from '../roleStoreModule'

export default {
  components: {
    BRow, BCol, BAlert, BLink,
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox
  },
  setup() {
    const roleData = ref(null)
    const permissionsData = ref(null)

    const ROLE_APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-role/fetchData', { id: router.currentRoute.params.id })
      .then((response) => { 
          roleData.value = response.data.data 
          permissionsData.value = response.data.permissions 
      })
      .catch(error => {
        roleData.value = undefined
      })

    return {
      roleData,
      permissionsData
    }
  },
}
</script>

<style>

</style>
